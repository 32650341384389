.app {
  height: 100vh;
  width: 100%;
}

.image-style-side {
  float: right; /* or 'left' depending on your needs */
  margin: 0 0 1em 1em; /* Adjust margins as needed */
  max-width: 100%; /* Ensure image does not exceed container width */
  height: auto; /* Maintain aspect ratio */
}

figure.image img {
  max-width: 100%;
  height: auto;
}